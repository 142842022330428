import {
	MIN_DYNAMIC_COVER_IMAGE_HEIGHT,
	MAX_DYNAMIC_COVER_IMAGE_HEIGHT,
	COVER_IMAGE_WIDTH_TO_HEIGHT_RATIO,
} from './pageCoverPictureConstants';

export const calcDynamicCoverImageHeight = (width): number => {
	const imgHeight = Math.floor(width / COVER_IMAGE_WIDTH_TO_HEIGHT_RATIO);
	if (imgHeight < MIN_DYNAMIC_COVER_IMAGE_HEIGHT) {
		return MIN_DYNAMIC_COVER_IMAGE_HEIGHT;
	} else if (imgHeight >= MAX_DYNAMIC_COVER_IMAGE_HEIGHT) {
		return MAX_DYNAMIC_COVER_IMAGE_HEIGHT;
	} else {
		return imgHeight;
	}
};
