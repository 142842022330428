function isDefined(path: string) {
	return path.split('.').reduce<Record<string, any>>((object, pathPart) => {
		return object && object[pathPart] ? object[pathPart] : null;
	}, window);
}

const lines: Record<string, boolean> = {};
function logOnce(message: string) {
	if (process.env.NODE_ENV !== 'production') {
		if (!lines[message]) {
			// eslint-disable-next-line no-console
			console.info(message);
			lines[message] = true;
		}
	}
}

// Export for testing
export function clearCache() {
	for (const key in lines) {
		delete lines[key];
	}
}

export function waitForGlobal(
	{
		listener,
		globalProperty,
		interval = 250,
	}: { listener: string; globalProperty: string; interval?: number },
	callback: () => void,
) {
	if (isDefined(globalProperty)) {
		logOnce(`window.${globalProperty} is already available for use by ${listener}.`);
		callback();
		return null;
	}
	const waitForGlobalInterval = setInterval(() => {
		logOnce(`${listener} is waiting for window.${globalProperty} to be available`);
		if (isDefined(globalProperty)) {
			logOnce(`window.${globalProperty} now available!`);
			clearInterval(waitForGlobalInterval);
			callback();
		}
	}, interval);
}
