import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onMoreActionButtonContainerClick = (evt, window, document) => {
	const node = evt?.target as HTMLElement;
	const offsetParent = evt?.target?.offsetParent as HTMLElement;

	if (!node && !offsetParent) return;

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = node?.getAttribute('data-id');
	const offsetParentId = offsetParent?.getAttribute('data-id');
	const dataId = elementId || (offsetParentId as string);

	try {
		const moreActionButton = offsetParentId === dataId ? offsetParent : node;
		window.__SSR_EVENTS_CAPTURE__.moreActionButton = evt;

		// spinner logic
		const spinner = document.querySelector(
			'[data-id="more-action-loading-spinner"]',
		) as HTMLDivElement;
		const moreActionButtonContainer = document.querySelector(
			'[data-id="content-tools-dropdown-menu-container"]',
		) as HTMLDivElement;

		if (spinner) {
			spinner.style.display = 'flex';
			spinner.style.fontSize = '14px';
			spinner.style.marginLeft = '10px';
		}
		if (moreActionButton) {
			moreActionButton.style.display = 'none';
			moreActionButtonContainer.style.display = 'none';
		}
		return;
	} catch (e) {
		captureSSRScriptErrors(dataId, e as Error);
	}
};

export const addSSRClickListeners = (document, window) => {
	if (window?.__HYDRATABLE__) {
		return;
	}
	// only add event click handlers if the SSR feature flag is enabled
	const actionButtonContainer = document?.querySelector("[data-id='page-more-action-button']");
	actionButtonContainer?.addEventListener(
		'click',
		function (evt) {
			onMoreActionButtonContainerClick(evt, window, document);
		},
		true,
	);
};

addSSRClickListeners(document, window);
