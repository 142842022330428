import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onCommentButtonClick = (evt, window, document) => {
	// Choose either the event's target node or offsetParent. The target node
	// could either be the star, inner space around star, or the border of the bounding box
	const node = evt?.target as HTMLElement;
	const offsetParent = evt?.target?.offsetParent as HTMLElement;

	if (!node && !offsetParent) return;

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = node?.getAttribute('data-id');
	const offsetParentId = offsetParent?.getAttribute('data-id');

	const dataId = elementId || (offsetParentId as string);
	try {
		const inlineCommentButton = offsetParentId === dataId ? offsetParent : node;
		window.__SSR_EVENTS_CAPTURE__.inlineCommentButton = evt;
		// spinner logic
		const spinner = document.querySelector(
			'[data-id="inline-comment-loading-spinner"]',
		) as HTMLDivElement;
		if (spinner) {
			spinner.style.display = 'flex';
			spinner.style.fontSize = '14px';
		}
		if (inlineCommentButton) {
			inlineCommentButton.style.display = 'none';
		}
		return;
	} catch (e) {
		captureSSRScriptErrors(dataId, e as Error);
	}
};

export const addSSRClickListeners = (document, window) => {
	if (window?.__HYDRATABLE__) {
		return;
	}
	const commentButton = document?.querySelector("[data-id='comment-button-placeholder']");
	commentButton?.addEventListener(
		'click',
		function (evt) {
			onCommentButtonClick(evt, window, document);
		},
		true,
	);
};

addSSRClickListeners(document, window);
