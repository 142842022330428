import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

export const onClickDismissAdminAnnouncementButtonHandler = (evt, document, window) => {
	const dismissButton = evt?.currentTarget as HTMLElement;

	if (!dismissButton) return;

	const dataId = dismissButton.getAttribute('data-id') || '';

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	} else if (Boolean(window.__SSR_EVENTS_CAPTURE__.dismissAdminAnnouncementBannerButton)) {
		return;
	}

	try {
		const spinner = document.querySelector(
			'[data-id="dismiss-admin-announcement-banner-loading-spinner"]',
		);

		if (spinner) {
			spinner.style.display = 'flex';
			spinner.style.fontSize = '14px';
		}

		if (dismissButton) {
			dismissButton.style.display = 'none';
		}

		window.__SSR_EVENTS_CAPTURE__.dismissAdminAnnouncementBannerButton = evt;
	} catch (e) {
		captureSSRScriptErrors(dataId, e as Error);
	}
};

export const addSSRClickListeners = (document, window) => {
	if (window?.__HYDRATABLE__) {
		return;
	}
	const dismissButton = document?.querySelector(
		"[data-id='ssr-dismiss-admin-announcement-button']",
	);

	dismissButton?.addEventListener(
		'click',
		function (evt) {
			onClickDismissAdminAnnouncementButtonHandler(evt, document, window);
		},
		true,
	);
};

addSSRClickListeners(document, window);
