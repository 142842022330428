export const addSSRClickListeners = (document: Document, window: any) => {
	/*Copied from next/packages/comment/src/utils.ts*/

	const FABRIC_PAGE_MARKER_REF_ATTRIBUTE = 'data-id';
	const TINY_PAGE_MARKER_REF_ATTRIBUTE = 'data-ref';

	const getHighlightFromMarkerRef: (
		markerRef?: string,
		additionalQueryScope?: string,
	) => HTMLElement | null = (markerRef, additionalQueryScope = '') => {
		if (!markerRef) {
			return null;
		}

		// We need to allow for additional query scope to be added to specify particular markerRef comments
		return document.querySelector(
			`${additionalQueryScope} [${FABRIC_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"], ${additionalQueryScope} [${TINY_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"]`,
		);
	};

	const adjustNodeForStickyHeader = (stickyHeaderElement: HTMLElement | null) => {
		let adjustedElement = stickyHeaderElement;

		const markerRef = getMarkerRef(adjustedElement);

		// If this is exists it's a commited comment, look for its counterpart in the real table
		// TODO: Support new highlights when we allow it
		if (markerRef) {
			// WS-2618 We have old macros that render tiny inline comments so we need to look for both fabric and tiny marker refs
			adjustedElement = getHighlightFromMarkerRef(markerRef, '.pm-table-wrapper');
		}

		return adjustedElement;
	};
	// Function to calculate a comment's offset taking nested elements into consideration
	const calculateElementOffset = (element: HTMLElement | null, isFabricHighlight?: boolean) => {
		let elementOffsetTop = 0;
		let nodeToMeasure = element;

		// Sticky table headers duplicate the elements in the DOM so we need to check if this is the real one or not
		// If the highlight has a parent with the class .pm-table-sticky-wrapper it is the fake one
		if (
			nodeToMeasure &&
			nodeToMeasure.closest &&
			nodeToMeasure.closest('.pm-table-sticky-wrapper')
		) {
			nodeToMeasure = adjustNodeForStickyHeader(nodeToMeasure);
		}
		// Iterate through the offset parents until we are back to the ak-renderer-wrapper to get the
		// proper offset Fabric Pages wrap the content in a .ak-renderer-wrapper element, but Tiny
		// pages do not have anything like that, but will return null when we get to <body>
		while (nodeToMeasure && !nodeToMeasure.classList.contains('ak-renderer-wrapper')) {
			elementOffsetTop += nodeToMeasure.offsetTop;
			nodeToMeasure = nodeToMeasure.offsetParent as HTMLElement | null;
		}

		if (isFabricHighlight === undefined) {
			return elementOffsetTop;
		} else {
			if (isFabricHighlight) {
				const rendererWrapper: HTMLElement | null = document.querySelector(
					'#content .ak-renderer-wrapper',
				);
				if (rendererWrapper) {
					elementOffsetTop += rendererWrapper.offsetTop;
				}
			} else {
				const pageMetadata: HTMLElement | null = document.querySelector(
					'#content-body .page-metadata-modification-info',
				);
				if (pageMetadata) {
					elementOffsetTop -= pageMetadata.offsetTop - pageMetadata.offsetHeight;
				}
			}
		}

		return elementOffsetTop;
	};

	const getMarkerRef = (element: HTMLElement | null) => {
		let markerRef: string | null = null;
		if (element) {
			markerRef = element.getAttribute(FABRIC_PAGE_MARKER_REF_ATTRIBUTE);
			if (!markerRef) {
				markerRef = element.getAttribute(TINY_PAGE_MARKER_REF_ATTRIBUTE);
			}
		}

		return markerRef;
	};

	// Function to scroll a comment into view with the correct offsets
	const scrollCommentIntoViewAndFetchOffset = (
		commentElement: HTMLElement | null,
		isFabricPage: boolean,
	) => {
		if (!commentElement) {
			return;
		}
		// We may be in an iframe so use window.top
		const currentWindowHeight = window.innerHeight || window?.top?.innerHeight;
		const currentPageOffset = window.scrollY || window?.top?.scrollY;
		const offset = calculateElementOffset(commentElement) + commentElement.offsetHeight;
		const rendererOffset =
			(document.querySelector('#content .ak-renderer-wrapper') as HTMLElement)?.offsetTop || 0;
		// Stop scrollTo being set if window doesn't provide all the needed info
		if (typeof currentWindowHeight !== 'number' || typeof currentPageOffset !== 'number') {
			return;
		}
		// If the position of the highlight is more than halfway down the screen, reposition it to the center
		if (
			offset + rendererOffset <= currentPageOffset ||
			offset + rendererOffset > currentPageOffset + currentWindowHeight * (2 / 3)
		) {
			const windowOffset = isFabricPage ? currentWindowHeight / 5 : currentWindowHeight / 3;

			window.scrollTo({
				top: offset - windowOffset,
			});

			return offset - windowOffset;
		}
	};

	const bindSSREventMouseEventHandlers = (target?: Node | null) => {
		if (!target) return;

		const onClick = (evt: Event) => {
			if (!window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__) {
				window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ = {};
			}

			const isFabricHighlight =
				(evt.target as HTMLElement)?.dataset?.markAnnotationType === 'inlineComment';
			const isTinyHighlight = (evt.target as HTMLElement)?.classList?.contains(
				'inline-comment-marker',
			);

			if (isFabricHighlight || isTinyHighlight) {
				if (!window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements']) {
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'] = [];
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['sidebarCommentOffset'] = '';
				}
				// Grab offset for InlineCommentsLoadingSkeleton placement
				let markOffsetTop = 0;

				const inlineHighlight = evt.target as HTMLElement;
				try {
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['clickedInlineHighlightElements'].push(
						inlineHighlight,
					);

					markOffsetTop = calculateElementOffset(inlineHighlight, Boolean(isFabricHighlight));

					// Save position for loading state skeletons
					window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['sidebarCommentOffset'] = markOffsetTop;
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e);
				}
				// Select sidebar skeleton and show it during ssr
				const sidebar = document.getElementById('inline-comment-sidebar-skeleton');

				if (sidebar) {
					sidebar.style.display = 'block';
					sidebar.style.top = `${markOffsetTop}px`;
					inlineHighlight.classList.add('active-highlight');
				}
			}
		};
		target.addEventListener('click', onClick, true);
	};

	/* Gets Sorted MarkerRefs in DOM and filters ones returned by back-end API for Unresolved comments */
	const getSortedMarkerRefs = (markerRefList: string[], rawMarks: NodeListOf<Element>) => {
		const marksArray = Array.from(rawMarks).map((node) => getMarkerRef(node as HTMLElement));
		/* In case of overlapping comments there will be multiple tags with the same markerRefs. We need to remove
      duplicates in that case so we can navigate between comments correctly */
		const distinctMarks = [...new Set(marksArray)];

		return distinctMarks.filter((mark) => markerRefList.includes(mark!)) as string[];
	};

	const getFocusedMarkIndex = (markerRefs: string[], focusedMarkerRef: string) => {
		const focusedMarkPosition = markerRefs.findIndex((mark) => mark === focusedMarkerRef);

		return focusedMarkPosition || 0;
	};

	const addFocusedInlineComment = (
		focusedMarkerRef: string,
		markerRefs: string[],
		marks: NodeListOf<Element>,
	) => {
		if (!window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__) {
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ = {};
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment'] = {};
		}

		const focusedElement: HTMLElement | null = document.querySelector(
			`[${FABRIC_PAGE_MARKER_REF_ATTRIBUTE}="${focusedMarkerRef}"]`,
		);

		// If we cannot query for correct focused element we want to exit
		if (!focusedElement) return;

		const sortedMarks = getSortedMarkerRefs(markerRefs, marks);

		const totalHighlights = sortedMarks.length;

		const isFabricHighlight = focusedElement?.dataset?.markAnnotationType === 'inlineComment';

		const isTinyHighlight = focusedElement?.classList?.contains('inline-comment-marker');

		if (isFabricHighlight || isTinyHighlight) {
			const markOffsetTop = calculateElementOffset(focusedElement, Boolean(isFabricHighlight));

			const commentPosition = getFocusedMarkIndex(sortedMarks, focusedMarkerRef);

			const isFabricPage = !!isFabricHighlight;

			const scrollToOffset = scrollCommentIntoViewAndFetchOffset(focusedElement, isFabricPage);
			// Set window values for SPA to read
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['markerRefOffset'] =
				markOffsetTop;
			// Set scroll value for page scroll if focusedComment is lower than top
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['scrollOffset'] =
				scrollToOffset;
			// Setting window values from data added to dom in addInlineHighlights
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['position'] = commentPosition;
			//Set total Comments
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['totalComments'] =
				totalHighlights;

			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['markerRef'] =
				focusedMarkerRef;

			// Select sidebar skeleton and show it during ssr
			const sidebar = document.getElementById('focused-inline-comment-sidebar-skeleton');
			// Prevent browser from moving the scroll position around on page load
			if (history) {
				history.scrollRestoration = 'manual';
			}
			/* if markOffsetTop is 0 dont render */
			if (sidebar && markOffsetTop) {
				sidebar.style.top = `${markOffsetTop}px`;
				sidebar.style.display = 'block';
				focusedElement.classList.add('active-highlight');
				// Set inline comment number
				const inlineCommentNumberContainer = sidebar.querySelector('#inline-comment-index');
				if (
					inlineCommentNumberContainer &&
					typeof commentPosition === 'number' &&
					typeof totalHighlights === 'number'
				) {
					inlineCommentNumberContainer.textContent = `${commentPosition + 1} of ${totalHighlights}`;
				}
				window.performance.mark('focused-inline-comment-fmp-ssr.rendered');
			}
		}
	};

	// Get focused marker ref from data attribute
	const focusedMarkerRef = document
		.getElementById('inline-comments-script')
		?.getAttribute('data-focused');
	/* get markerRefs from data-mark attribute - comes in as a string
     convert to array */
	const markerRefs = document
		.getElementById('inline-comments-script')
		?.getAttribute('data-marks')
		?.split(',');
	if (markerRefs && Array.isArray(markerRefs) && markerRefs.length > 0) {
		const marks: NodeListOf<Element> = document.querySelectorAll(
			`.inline-comment-marker, [data-mark-annotation-type="inlineComment"]`,
		);
		/* Handle Focused Comment SSR */
		if (focusedMarkerRef) {
			addFocusedInlineComment(focusedMarkerRef, markerRefs, marks);
		} else {
			/* Add click listeners to SSR'd inline comments - not for focused inline comment page */
			Array.from(marks).forEach((el) => {
				/* get markerRef from data-id for fabric, data-ref for tiny*/
				const markerRef =
					el.getAttribute(FABRIC_PAGE_MARKER_REF_ATTRIBUTE) ||
					el.getAttribute(TINY_PAGE_MARKER_REF_ATTRIBUTE);
				if (markerRef && markerRefs?.includes(markerRef)) {
					bindSSREventMouseEventHandlers(el);
				}
			});
		}
	}
};

addSSRClickListeners(document, window);
