export type SSRErrorEntries = { [key: string]: Error[] };

/**
 * This function is only meant to be used in ssr scripts.
 * SSR Scripts are snippets that runs before SPA on the client.
 *
 * We want to capture any errors came out from them and report once SPA is loaded.
 */
export const captureSSRScriptErrors = (scope: string, error: Error) => {
	// Put error to console only when this is not a production environment
	// Otherwise hide it from users if they cannot act upon these
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line no-console
		console.error(error);
	}

	if (!window.__SSR_CLIENT_ERRORS__) {
		window.__SSR_CLIENT_ERRORS__ = {} as SSRErrorEntries;
	}

	// capturing multiple errors in SSR for the same scope
	if (window.__SSR_CLIENT_ERRORS__[scope]) {
		window.__SSR_CLIENT_ERRORS__[scope].push(error);
	} else {
		window.__SSR_CLIENT_ERRORS__[scope] = [error];
	}
};
