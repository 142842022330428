import { CLICKED_BYLINE_SSR_MARK } from '../src/components/AnalyticsByLine/constants';
import { SSREvent } from '../src/hooks/SSREvent';

// Exported for testing
export const TEST_ID_BYLINE = 'analytics-byline';
export const TEST_ID_DIALOG_SKELETON = 'analytics-dialog-skeleton';

const SSR_EVENTS_CAPTURE_KEY = 'analyticsByLine';

const initSsrEvents = () => {
	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	if (!window.__SSR_EVENTS_CAPTURE__?.[SSR_EVENTS_CAPTURE_KEY]) {
		window.__SSR_EVENTS_CAPTURE__[SSR_EVENTS_CAPTURE_KEY] = {};
	}
};

const onByLineEvent = (eventType: SSREvent.FOCUS | SSREvent.HOVER, event) => {
	initSsrEvents();
	if (
		window &&
		window.__SSR_EVENTS_CAPTURE__ &&
		!window.__SSR_EVENTS_CAPTURE__?.[SSR_EVENTS_CAPTURE_KEY]
	) {
		window.__SSR_EVENTS_CAPTURE__[SSR_EVENTS_CAPTURE_KEY][eventType] = event;
	}
	return;
};

const onByLineClick = (bylineElem, dialogElem, event) => {
	initSsrEvents();
	if (
		window &&
		window.__SSR_EVENTS_CAPTURE__ &&
		!window.__SSR_EVENTS_CAPTURE__?.[SSR_EVENTS_CAPTURE_KEY]?.[SSREvent.CLICK]
	) {
		dialogElem.style.display = 'block';

		// Based on byline position center the dialog skeleton
		const dialogWidth = dialogElem.getBoundingClientRect().width;
		const byLineWidth = bylineElem.getBoundingClientRect().width;
		const dialogLeftOffset = bylineElem.offsetLeft + byLineWidth / 2 - dialogWidth / 2;

		dialogElem.style.left = `${dialogLeftOffset}px`;
		window.__SSR_EVENTS_CAPTURE__[SSR_EVENTS_CAPTURE_KEY][SSREvent.CLICK] = event;
		window.performance.mark(CLICKED_BYLINE_SSR_MARK);
	}
	return;
};

export const addSSREventListeners = () => {
	const byline = document?.querySelector(`[data-testid="${TEST_ID_BYLINE}"]`);
	const dialog = document?.querySelector(`[data-testid="${TEST_ID_DIALOG_SKELETON}"]`);

	if (!byline || !dialog) return;

	byline?.addEventListener(
		'click',
		function (evt) {
			initSsrEvents();
			onByLineClick(byline, dialog, evt);
		},
		true,
	);
	byline?.addEventListener(
		'hover',
		function (evt) {
			initSsrEvents();
			onByLineEvent(SSREvent.HOVER, evt);
		},
		true,
	);
	byline?.addEventListener(
		'focus',
		function (evt) {
			initSsrEvents();
			onByLineEvent(SSREvent.FOCUS, evt);
		},
		true,
	);
};

addSSREventListeners();
