import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onFavoriteButtonContainerClick = (evt, window) => {
	// Choose either the event's target node or offsetParent. For Favorite Button, the target node
	// could either be the star, inner space around star, or the border of the bounding box
	const node = evt?.target as HTMLElement;
	const offsetParent = evt?.target?.offsetParent as HTMLElement;

	if (!node && !offsetParent) return;

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = node?.getAttribute('data-id');
	const offsetParentId = offsetParent?.getAttribute('data-id');

	const dataId = elementId || (offsetParentId as string);
	// optimistic UI update: one user click is captured - subsequent SSR clicks not handled
	if (Boolean(window.__SSR_EVENTS_CAPTURE__.favoriteButton)) {
		return;
	}
	const FILLED_COLOR = '#FFAB00'; // yellow
	const UNFILLED_COLOR = '#42526E'; // grey

	try {
		window.__SSR_EVENTS_CAPTURE__.favoriteButton = evt;

		// Depending on user click, offsetParent could be the bounding button if
		// the inner star/white space span is clicked. To avoid querying the DOM again,
		// both nodes are checked
		const targetNode = offsetParentId === dataId ? offsetParent : node;
		const isStarred = Boolean(dataId === 'page-unstar-button');
		const svg = targetNode.querySelector('svg > path') as HTMLElement;

		// Update css
		svg.setAttribute('fill', `${isStarred ? UNFILLED_COLOR : FILLED_COLOR}`);
		svg.setAttribute(
			'd',
			isStarred
				? 'M12 16.373l3.98 2.193-.76-4.655 3.276-3.347-4.524-.69L12 5.687l-1.972 4.189-4.524.689L8.78 13.91l-.762 4.655L12 16.373zm0 2.283l-3.016 1.662a2 2 0 01-2.939-2.075l.599-3.656-2.57-2.624a2 2 0 011.129-3.377l3.47-.528 1.518-3.224a2 2 0 013.618 0l1.519 3.224 3.47.528a2 2 0 011.127 3.377l-2.569 2.624.599 3.656a2 2 0 01-2.94 2.075L12 18.656z'
				: 'M12.072 17.284l-3.905 2.053a1 1 0 01-1.451-1.054l.745-4.349-3.159-3.08a1 1 0 01.554-1.705l4.366-.635 1.953-3.956a1 1 0 011.794 0l1.952 3.956 4.366.635a1 1 0 01.555 1.705l-3.16 3.08.746 4.349a1 1 0 01-1.45 1.054l-3.906-2.053z',
		);
		return;
	} catch (e) {
		captureSSRScriptErrors(dataId, e as Error);
	}
};

export const addSSRClickListeners = (document, window) => {
	if (window?.__HYDRATABLE__) {
		return;
	}
	// only add event click handlers if the SSR feature flag is enabled
	const pageUnstarButton = document?.querySelector("[data-id='page-unstar-button']");
	const pageStarButton = document?.querySelector("[data-id='page-star-button']");

	const favoriteButton = pageUnstarButton || pageStarButton;
	favoriteButton?.addEventListener(
		'click',
		function (evt) {
			onFavoriteButtonContainerClick(evt, window);
		},
		true,
	);
};

addSSRClickListeners(document, window);
