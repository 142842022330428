import { waitForGlobal } from '@confluence/wrm/entry-points/waitForGlobal';

const setAjsMetadata = () => {
	waitForGlobal(
		{
			listener: 'ssrLegacyBridge',
			globalProperty: 'AJS.Meta.set',
			interval: 50,
		},
		() => {
			const ajsMetaParamsStr = (window as any).__SSR_AJS_META__;
			let ajsMetaParams = [];
			try {
				ajsMetaParams = JSON.parse(ajsMetaParamsStr);
			} catch (error) {
				/* NOOP */
			}

			// We have 2 legacy bridge here based on graphql
			// Another in packages/confluence-frontend-server/src/components/LegacyBridge/LegacyBridge.js
			// based on Redux. We need to let the legacy one know we have already dealt with these meta
			// tags don't override.
			if (ajsMetaParams?.length > 0) {
				window.AJS.Meta.set('has-next-legacy-bridge', true);

				ajsMetaParams.forEach(({ key, value }) => {
					window.AJS.Meta.set(key, value);

					// This logic is to match the classic LegacyBridge, as apparently it was missing in
					// the LegacyBridgeNext and some macros rely on AJS.params as opposed to AJS.Meta.
					if (key === 'page-id') {
						(window.AJS as any).params.pageId = value;
					}

					if (key === 'space-key') {
						(window.AJS as any).params.spaceKey = value;
					}
				});
			}
		},
	);
};

setAjsMetadata();
