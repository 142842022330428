export const DEFAULT_POSITION_VALUE = 50;
export const MODAL_HEIGHT = 565;
export const SMALL_MODAL_WIDTH = 360;
export const MODAL_WIDTH = 515;
export const REFERRAL_LINK = '?utm_source=Confluence&utm_medium=referral';
export const DEFAULT_QUERY_WORD = 'growing';
export const UNSPLASH_LICENSE_LINK = 'https://unsplash.com/license';
export const UNSPLASH_TERMS_OF_SERVICE_LINK = 'https://unsplash.com/terms';
export const MAX_WINDOW_WIDTH_BEFORE_OVERFLOW = 615;
export const MIN_DYNAMIC_COVER_IMAGE_HEIGHT = 140;
export const MAX_DYNAMIC_COVER_IMAGE_HEIGHT = 750;
export const COVER_IMAGE_WIDTH_TO_HEIGHT_RATIO = 38 / 7;
export const CALCULATE_SCALED_COVER_IMAGE_HEIGHT =
	'min(max(calc(calc( 100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px) ) * (7 / 38)), 140px), 750px)';
export const FIXED_WIDTH_OPTION_FF =
	'confluence.frontend.custom-sites.fixed-width-header-image-option';
export const COVER_IMAGE_FIXED_WIDTH = 800;
export const COVER_IMAGE_FIXED_HEIGHT = 147;
