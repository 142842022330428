import { calcDynamicCoverImageHeight } from '@confluence/page-cover-picture/entry-points/calcDynamicCoverImageHeight';

export const upateCoverPictureDimension = (document) => {
	// Only run script if found this element
	const enableScript = document.querySelector('[data-id="ssr-cover-picture-dimension"]');
	if (!enableScript) {
		return;
	}

	const editorParent = document.querySelector('.fabric-editor-popup-scroll-parent');
	const parentWidth = editorParent?.clientWidth || 0;
	if (!parentWidth) {
		return;
	}
	const coverImageWrapper = document.querySelector('[data-id="cover-pic-and-button-wrapper"]');
	if (!coverImageWrapper) {
		return;
	}
	const height = calcDynamicCoverImageHeight(parentWidth);
	coverImageWrapper.style.width = `${parentWidth}px`;
	coverImageWrapper.style.height = `${height}px`;
};
upateCoverPictureDimension(document);
